.hero {
    width: 100%,;
    height: 100vh;
}

.heroImg {
    width: 100%;
    height: 100vh;
    object-fit: cover;
}

.content {
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contentCard {
    position: absolute;
    top: 85%; right: 50%;
    transform: translate(50%,-50%);
}

.textStroke { 
    text-shadow:
    1.5px 1.5px 0 #000,
    -1.5px 1.5px 0 #000,
    -1.5px -1.5px 0 #000,
    1.5px -1.5px 0 #000;
}

@media screen and (min-width: 768px) {
    .textStroke { 
        text-shadow:
        2px 2px 0 #000,
        -2px 2px 0 #000,
        -2px -2px 0 #000,
        2px -2px 0 #000;
    }
  }

  @media screen and (min-width: 1024px) {
    .textStroke { 
        text-shadow:
        2px 2px 0 #000,
        -2px 2px 0 #000,
        -2px -2px 0 #000,
        2px -2px 0 #000;
    }
  }

.textStrokeSmall { 
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
}


