@tailwind base;
@tailwind components;
@tailwind utilities;


.pdf {
  aspect-ratio: 4 / 3;
  margin: 0;
  padding: 0;
}

body, html {
    margin: 0;
    overflow-x: hidden;
    width:100vw;
     /* width */
}

video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
-webkit-filter: blur(5px); /* Safari 6.0 - 9.0 */
filter: blur(5px);

}

.pictureheight {
  height: 22rem;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 768px) {
  .pictureheight {
    height: 28rem;
  }
}

@media screen and (min-width: 1200px) {
  .pictureheight {
    height: 32rem;
  }
}

body {
  width: 100vw;
}

.customFont {
  font-family: 'Varela', sans-serif;
}

.servicesImage {
  height: 14rem;
  width: 24rem;
} 

.imageSize {
  width: 40rem;
}

.imgtest:hover {
  -webkit-transform:scale(1.1); /* or some other value */
  transform:scale(1.1);
  transition: ease 1s;
}

.img-shadow {
  transition: ease 1s;
  box-shadow: 3px 3px 3px gray;
}

@media screen and (min-width: 768px) {
  .servicesImage {
    height: 18rem;
    width: 30rem;
  }

  .servicesText {
    width: 30rem;
  }

  .animate {
    animation: scroll 30s linear infinite;
  }

  .animate2 {
    animation: scroll 30s linear infinite;
    animation-direction: reverse;
  }

  .imgtest:hover {
    -webkit-transform:scale(1.2); /* or some other value */
    transform:scale(1.2);
    transition: ease 1s;
  }
  
  .img-shadow {
    transition: ease 1s;
    box-shadow: 3px 3px 3px gray;
  }
  
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

@media screen and (min-width: 1024px) {
  .servicesImage {
    height: 22rem;
    width: 36rem;
  }
  
  .servicesText {
    width: 36rem;
  }
}

.servicesText {
  width: 24rem;
}

@keyframes scroll {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

.animate {
  animation: scroll 25s linear infinite;
}

.animate2 {
  animation: scroll 25s linear infinite;
  animation-direction: reverse;
}

